// parallax
// if (windowWidth <= windowSm) {
if (!isPC) {
  parallax();
}
function parallax(){

  const options = {
    rootMargin: '0% 0% -20% 0%',
    threshold: [0, .5, 1.0],
  }

  const targets = document.querySelectorAll('.prlxView');
  const targetsArray = Array.prototype.slice.call(targets, 0);

  const callback = (entries, observer) => {
    const entriesArray = Array.prototype.slice.call(entries, 0);
    entriesArray.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('-animated');
      }
      // else {
      //   entry.target.classList.remove('-animated');
      // }
    });
  };

  const observer = new IntersectionObserver(callback, options);
  
  targetsArray.forEach(target => {
    observer.observe(target);
  });

}