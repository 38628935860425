// top page js

topFunction();

function topFunction(){
  if (!document.body.classList.contains('home')) return;
  _fvFitting();
  loading();
  mousePrallax();
  panelStart();
  scrollFunction();
  headerSP();
  anchorFuncSP();
}

function _fvFitting(){
  const slider = document.getElementsByClassName('c-slider-fv');
  if (!slider.length) return;
  _fitHightSP();
  window.addEventListener('resize', function(){
    _fitHightSP();
  });
  function _fitHightSP(){
    let windowWidth = window.innerWidth;
    // if (windowWidth > windowSm) return;
    if (isPC) return;
    const dH = document.documentElement.clientHeight;
    slider[0].style.height = dH+'px';
  }
}

function loading(){
  const keepTime = 7000;
  const loadFlg = Cookies.get('loaded');
  const loading = $('.c-loading');
  const cushion = $('.c-cushion');

  _resize();
  $(window).on('resize', function(){
    _resize();
  });
  $('.c-cursor').addClass('-loading');

  const start = new Date().getTime();
  if(loadFlg){
    loading.hide();
    cushion.hide();
    sliderFv();
    signDraw();
    $('.c-cursor').removeClass('-loading');
  }else{
    $(window).on('load',function () {
      const now = new Date().getTime();
      if (now - start <= keepTime) {
        setTimeout(function(){
          stopLoading();
        },keepTime - (now - start));
        return;
      } else {
        stopLoading();
      }
    });
  }
  
  // setTimeout(stopLoading(),keepTime*2);
  
  function stopLoading(){
    loading.fadeOut(1000);
    $('.c-cursor').removeClass('-loading');
    $('.c-cushion').addClass('-animated');
    setTimeout(function(){
      // signDraw();
      setTimeout(function(){
        sliderFv();
        cushion.fadeOut(1500);
        Cookies.set('loaded',true);
      },8000);
      setTimeout(function(){
        signDraw();
      },5400);
    },500);
  }

  function _resize(){
    let baseW = 0;
    let windowWidth = window.innerWidth;
    // if (windowWidth > windowSm) {
    if (isPC) {
      baseW = 800;
    }else{
      baseW = 300;
    }
    const rate = windowWidth / baseW;
    $('.c-loading__symbol').css({
      'transform': 'translate(-50%,0) scaleX('+rate+')',
    });
  }
}


function signDraw(){
  const anime1 = function(){
    new Vivus(
      'sign1',
      {
        type: 'scenario-sync',
        duration: 16,
        forceRender: false ,
        animTimingFunction:Vivus.EASE
      }
    );
  }
  anime1();
  const anime2 = function(){
    new Vivus(
      'sign2',
      {
        type: 'scenario-sync',
        duration: 16,
        forceRender: false ,
        animTimingFunction:Vivus.EASE
      }
    );
  }
  anime2();
}



function sliderFv(){
  const duration = 5000;
  const slider = document.getElementsByClassName('c-slider-fv');
  if (!slider.length) return;

  const slide = document.getElementsByClassName('c-slider-fv__item');
  const label = document.getElementsByClassName('c-slider-fv__label')[0];

  // slide[0].classList.add('-current');
  _change(slide[0]);

  let indexNum = 0;
  setInterval(function(){
    _change(slide[_count()]);
  }, duration);

  function _change(target){
    let keepNum = 0;
    for (let i = 0; i < slide.length; i++) {
      const item = slide[i];

      if (item.classList.contains('-current')) keepNum = i;

      item.classList.remove('-current');
    }

    const text = target.getElementsByClassName('c-slider-fv__caption')[0].innerHTML;
    label.innerHTML = text;
    target.classList.add('-current');

    _keep(slide[keepNum]);
  }

  function _count(){
    indexNum++;
    if (indexNum > slide.length - 1) {
      indexNum = 0;
    }
    return indexNum;
  }

  function _keep(keepItem){
    keepItem.classList.add('-keep');
    setTimeout(function(){
      keepItem.classList.remove('-keep');
    }, duration - 1);
  }
}



function mousePrallax(){
  // if (windowWidth > windowSm) {
  if (isPC) {
    const layers = document.getElementsByClassName('layerParallax');
    for (let i = 0; i < layers.length; i++) {
      const layer = layers[i];
      const parallax = new Parallax(layer, {
        clipRelativeInput: true, //要素の端にカーソルが達したら動きを止める
        hoverOnly: true, //カーソルが要素の上にある場合のみ有効
        invertX: true, //X軸の動きを反転。falseにするとマウスと同じ方向に動く
        invertY: true, //Y軸の動きを反転。falseにするとマウスと同じ方向に動く
        limitX: false, //X軸の動きを制限する
        limitY: false, //Y軸の動きを制限する
        scalarX: 10.0, //X軸の移動速度と範囲を増減
        scalarY: 10.0, //Y軸の移動速度と範囲を増減
        // frictionX: .1, //X軸のレイヤーの速度。0.00〜1.00の範囲内で指定可能
        // frictionY: .1, //Y軸のレイヤーの速度。0.00〜1.00の範囲内で指定可能
        originX: .5, //X軸のマウスの初期位置。デフォルトの0.5は画面または要素の中心
        originY: .5, //Y軸マウスの初期位置。デフォルトの0.5は画面または要素の中心
      });
    }
  }
  $(window).on('scroll', function(){
    const scroll = $(window).scrollTop();
    // if (windowWidth > windowSm) return;
    if (isPC) return;
    $('.panelScroll').each(function(){
      const bg = $(this).children('.layerParallax');
      
      if(!bg.length) return;

      const dH = $(window).height();
      const start = $(this).offset().top;
      const end = start + $(this).outerHeight();

      if(scroll <= start - dH || end <= scroll) return;
      
      const top = scroll - start;
      const rate = (top / dH) / 3 * 100 * -1;
      bg.css({
        'top': top+'px',
        'transform': 'translateY('+rate+'%)',
      });
    });
  });
}


function panelStart(){
  var windowWidth = window.innerWidth;
  // if (windowWidth > windowSm) {
  if (isPC) {
    if (location.hash == '#page1' || location.hash == '') {
      // $(window).on('load',function(){
        $('.panelScroll[data-panel="01"]').addClass('-viewing');
        $('.c-frame').addClass('-hide');
      // });
    }
  }else{
    // $(window).on('load',function(){
      $('.panelScroll[data-panel="01"]').addClass('-viewing');
    // });
  }
}


function scrollFunction(){
  let current = 0;
  let flg = true;
  const keepDuration = 1200;
  let panel = '.panelScroll';

  if (location.hash.indexOf('page') != -1) {
    let windowWidth = window.innerWidth;
    const num = location.hash.replace('#page','');
    // if (windowWidth > windowSm) {
    if (isPC) {
      _loadPanelMove(num);
    }else{
      const panel = $('.panelScroll');
      const setLine = panel.eq(num-1).offset().top;
      $(window).scrollTop(setLine);
      // $('html, body').animate({
      //   scrollTop: setLine,
      // }, 600, 'swing');
    }
  }

  function _loadPanelMove(num){
    $(panel).eq(num-1).prevAll().addClass('-viewing -passed');
    $(panel).eq(num-1).removeClass('-hide -passed').addClass('-viewing -animated');
    $(panel).eq(num-1).nextAll().removeClass('-viewing -passed');
    
    $('.panelScroll.-viewing:not(.-passed)').prev('.panelScroll').prevAll().addClass('-hide');
    $('.panelScroll.-viewing:not(.-passed)').next('.panelScroll').nextAll().addClass('-hide');

    current = num-1;
    
    // The rearmost point of the panel
    if ($('.panelScroll.-viewing:not(.-passed)').index() + 1 == $(panel).length) {
      $('.c-scroll__text').text('Page Top');
    }else{
      $('.c-scroll__text').text('Scroll');
    }
  }


  var windowWidth = window.innerWidth;
  // if (windowWidth > windowSm) {
  if (isPC) {
    _build();
  }
  function _matchFunc(){
    if(window.matchMedia("(max-width:1024px)").matches){
      //SP
      window.location.reload();
    }
    if(window.matchMedia("(min-width:1025px)").matches){
      //PC
      window.location.reload();
    }
  }
  window.matchMedia("(max-width:960px)").addListener(_matchFunc);

  function _build(){
    $('body').css({
      'overflow': 'hidden',
    });
    var panel = '.panelScroll';
    $(panel).css({
      'height': screen.innerHeight,
    });
    // for (var i = 0; i < $(panel).length; i++) {
    //   $(panel).eq(i).attr('id','page'+(i+1));
    //   $(panel).eq(i).css({
    //     'z-index': $(panel).length - i,
    //   });
    // }

    // キー入力
    $(document).on('keydown', function(e) {
      var windowWidth = window.innerWidth;
      // if (windowWidth > windowSm) {
      if (isPC) {
        // 34 pagedown , 35 end , 40 ↓
        if (e.keyCode == '34' || e.keyCode == '35' || e.keyCode == '40') {
          _panelDown();
        }
        // 33 pageup , 36 home , 38 ↑
        if (e.keyCode == '33' || e.keyCode == '36' || e.keyCode == '38') {
          _panelUp();
        }
      }
    });
    var $container = $('.displayPanel');
    // スクロールの方向判定
    var mousewheelevent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
    $(document).on(mousewheelevent,function(e){
      var windowWidth = window.innerWidth;
      // if (windowWidth > windowSm) {
      if (isPC) {
        // e.preventDefault();
        var delta = e.originalEvent.deltaY ? -(e.originalEvent.deltaY) : e.originalEvent.wheelDelta ? e.originalEvent.wheelDelta : -(e.originalEvent.detail);

        if (delta < 0){
          if(_isLocalScrollable(e) == 'hold') return;
          // if(_isLocalScrollable(e) == true || _isLocalScrollable(e) == 'start') return;
          _panelDown();
        } else {
          if(_isLocalScrollable(e) == 'hold') return;
          // if(_isLocalScrollable(e) == true || _isLocalScrollable(e) == 'end') return;
          _panelUp();
        }
      }
    });
    //スマホのタッチスクロール方向の判定。
    var isTouch = ('ontouchstart' in window);
    $container.on({
      'touchstart': function(e){
        var windowWidth = window.innerWidth;
        // if (windowWidth > windowSm) {
        if (isPC) {
          this.pageY = (isTouch ? event.changedTouches[0].pageY : e.pageY);
          //スクロール方向を測る基準値
          this.topBegin = parseInt($(this).css('top')); 
          this.top = parseInt($(this).css('top'));
          this.touched = true;
        }
      },
      'touchmove': function(e){
        var windowWidth = window.innerWidth;
        // if (windowWidth > windowSm) {
        if (isPC) {
          if(!this.touched){return;}
          e.preventDefault();
          //動きを無効化したうえで、タッチ位置のの格納していく
          this.top = this.top - (this.pageY - (isTouch ? event.changedTouches[0].pageY : e.pageY));
          this.pageY = (isTouch ? event.changedTouches[0].pageY : e.pageY);
        }
      },
      'touchend': function(e){
        var windowWidth = window.innerWidth;
        // if (windowWidth > windowSm) {
        if (isPC) {
          if (!this.touched) {return;}
          // タッチ判定をfalseに
          this.touched = false;
          // 最初に取得したタッチ位置とmove後に入れたタッチ位置を比較してスクロール方向を判定。
          if((this.topBegin) > this.top){
            _panelDown();
          } else if((this.topBegin) < this.top){
            _panelUp();
          }
        }
      }
    });

    $('.c-menu a[href*="#"], .c-sign[href*="#"]').on('click',function(e){
      e.preventDefault();
      const href = $(this).attr('href');
      const num = Number (href.split('#page')[1]);
      _moveSlectPanel(num);

      const btn = $('.c-btn-menu');
      const menu = $('.c-menu');
      menu.stop().fadeOut(1000);
      $('body').removeClass('-hold');
      btn.removeClass('-active');
    });

    function _panelDown(){
      if(_isHold()) return;
      const now  = $(panel).eq(current);
      const next = $(panel).eq(current+1);
      var parentS = $(now).scrollTop();
      var parentH = now.innerHeight();
      var childH = 0;
      for (var i = 0; i < now.children().length; i++) {
        childH += now.children().eq(i).innerHeight();
      }
      childH = Math.floor(childH);
      // if (parentH + parentS < childH) {
      //   return;
      // }
      
      if ($('.panelScroll.-viewing:not(.-passed)').index() + 1 == $(panel).length && flg == true) {
        _moveSlectPanel(1);
        return;
      }
      if (next.length && flg == true) {
        flg = false;
        current = current + 1;

        now.addClass('-passed');
        next.addClass('-viewing');

        _afterAction(current);
        setTimeout(function(){
          flg = true;
        },keepDuration);
      }
    }
    function _panelUp(){
      if(_isHold()) return;
      const now  = $(panel).eq(current);
      const next = $(panel).eq(current-1);
      var parentS = $(now).scrollTop();
      if (parentS > 0) {
        return;
      }
      if (current != 0 && next.length && flg == true) {
        flg = false;
        current = current - 1;

        next.removeClass('-passed');
        now.removeClass('-viewing');

        _afterAction(current);
        setTimeout(function(){
          flg = true;
        },keepDuration);
      }
    }

    function _afterAction(next){
      const currentPanel = $('.panelScroll.-viewing:not(.-passed)');
      const prevPanel = currentPanel.prev('.panelScroll');
      const nextPanel = currentPanel.next('.panelScroll');
      currentPanel.removeClass('-hide').addClass('-animated');
      prevPanel.removeClass('-hide');
      nextPanel.removeClass('-hide');
      prevPanel.prevAll().addClass('-hide');
      nextPanel.nextAll().addClass('-hide');
      location.hash = '#page'+(next+1);
      if (location.hash == '#page1') {
        $('.c-frame').addClass('-hide');
      }else{
        $('.c-frame').removeClass('-hide');
      }

      // The rearmost point of the panel
      if (currentPanel.index() + 1 == $(panel).length) {
        $('.c-scroll__text').text('Page Top');
      }else{
        $('.c-scroll__text').text('Scroll');
      }
    }

    function _isLocalScrollable(e){
      const parent = $(e.target).parents('.c-area-scroll');
      if (parent.length) return 'hold';
      // if (!parent.length) return false;
      // const parentS = parent.scrollTop();
      // const parentH = parent.innerHeight();
      // const spaceAfter = 150;
      // let childH = 0;
      // for (let i = 0; i < parent.children().length; i++) {
      //   childH += parent.children().eq(i).innerHeight();
      // }
      // childH += spaceAfter;
      // if (parentH + parentS < childH && parentS != 0) {
      //   return true;
      // }else if(parentH < childH && parentS == 0){
      //   return 'start';
      // }else if(parentH >= childH){
      //   return false;
      // }else{
      //   return 'end';
      // }
    }

    function _isHold(){
      return $('body').hasClass('-hold');
    }

    function _moveSlectPanel(num){
      flg = false;
      const allPanel = $(panel);
      const nowPanel = allPanel.eq(num-1);

      // 現在のパネルを除外し hide
      allPanel.not('.-viewing:not(.-passed)').addClass('-hide');
      // すべてのパネルをフェードアウト
      allPanel.removeClass('-viewing -passed');
      
      current = num-1;
      
      setTimeout(function(){
        // 次のパネル hide 解除
        nowPanel.removeClass('-hide');
        setTimeout(function(){
          nowPanel.addClass('-viewing');
          nowPanel.prevAll().addClass('-viewing -passed -hide');
          setTimeout(function(){
            _afterAction(num-1);
          },595);
        },10);
      },595);
      
      setTimeout(function(){
        flg = true;
      },keepDuration);
    }
  }
}


function headerSP(){
  $(window).on('scroll',function(){
    const scroll = $(window).scrollTop();
    const fv = $('.c-slider-fv');
    const start = fv.offset().top
    const dH = fv.innerHeight();
    if (scroll > start + dH){
      $('.c-frame').stop().addClass('-sp-visible');
    }else{
      $('.c-frame').stop().removeClass('-sp-visible');
    }
  });
}


function anchorFuncSP(){
  const menu = $('.c-menu');
  const btn = $('.c-btn-menu');
  
  $('.c-menu a[href*="#"]').on('click', function(e){
    // if (windowWidth > windowSm) return;
    if (isPC) return;
    const target = $(e.target);
    const panel = $('.panelScroll');
    const num = Number (target.attr('href').split('#page')[1]) - 1;
    e.preventDefault();
    menu.stop().fadeOut(1000);
    $('body').removeClass('-hold');
    btn.removeClass('-active');
    $('body').css({
      'position': '',
      'top': '',
      'left': '',
      'right': '',
    });
    const setLine = panel.eq(num).offset().top;
    if(setLine <= 0){
      $('.c-frame').removeClass('-sp-visible');
    }
    $(window).scrollTop(setLine);
    console.log(setLine);
    // $('html, body').animate({
    //   scrollTop: setLine,
    // }, 600, 'swing');
  });
  $('.c-sign[href*="#"]').on('click', function(e){
    // if (windowWidth > windowSm) return;
    if (isPC) return;
    const panel = $('.panelScroll');
    const num = 0;
    e.preventDefault();
    const setLine = panel.eq(num).offset().top;
    $('html, body').animate({
      scrollTop: setLine,
    }, 600, 'swing');
  });
}