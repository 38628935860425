// common js

const windowSm = 960;
let windowWidth = window.innerWidth;
let isPC = true;

// if (windowWidth > windowSm) {
//   console.log(pc);
// } else {
//   console.log(sp);
// }

commonFunction();

function commonFunction(){
  useragentFunc();
  loadedClass();
  customCursor();
  galleryFunc();
  goodsTab();
  menuFunc();
  gallery();
  modalServices();
  modalMovie();
  accordionSP();
}

function useragentFunc(){
  let setting = '';
  if (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0) {
    // sp
    setting = 'width=device-width,initial-scale=1';
    isPC = false;
  // } else if (navigator.userAgent.indexOf('iPad') > 0 || navigator.userAgent.indexOf('Android') > 0) {
  //   // tablet
  //   setting = 'width=768';
  //   isPC = false;
  } else {
    // pc
    // if ('ontouchend' in document) {
    //   setting = 'width=768';
    //   isPC = false;
    // }else{
      setting = 'width=device-width,initial-scale=1';
      _flg();
      $(window).on('resize',function(){
        _flg();
      });
      function _flg(){
        if (windowWidth > windowSm) {
          isPC = true;
        } else {
          isPC = false;
        }
      }
    // }
  }
  $('meta[name="viewport"]').attr('content', setting);
}

function loadedClass(){
  $(window).on('load', function(){
    $('body').addClass('-loaded');
  });
}


function customCursor(){
  const target = document.getElementById('cursor');
  let mouseX = 0;
  let mouseY = 0;
  if(!target) return;
  
  document.addEventListener('mousemove', function(e) {
    mouseX = e.clientX;
    mouseY = e.clientY;
    target.style.left = mouseX+'px';
    target.style.top =  mouseY+'px';
  });

  const links = document.querySelectorAll('a, button');
  for (let i = 0; i < links.length; i++) {
    links[i].addEventListener('mouseover', function (e) {
      target.classList.add('-hover');
    });
    links[i].addEventListener('mouseout', function (e) {
      target.classList.remove('-hover');      
    });
  }

  const linksInGoods = document.querySelectorAll('.o-content-goods__tab');
  const panel06On = document.querySelector('.panelScroll[data-panel="06"] .panelBg.-on');
  for (let i = 0; i < linksInGoods.length; i++) {
    linksInGoods[i].addEventListener('mouseover', function (e) {
      panel06On.classList.add('-active');
    });
    linksInGoods[i].addEventListener('mouseout', function (e) {
      panel06On.classList.remove('-active');
    });
  }

  const linksInServices = document.querySelectorAll('.o-content-services a');
  const panel07On = document.querySelector('.panelScroll[data-panel="07"] .panelBg.-on');
  for (let i = 0; i < linksInServices.length; i++) {
    linksInServices[i].addEventListener('mouseover', function (e) {
      panel07On.classList.add('-active');
    });
    linksInServices[i].addEventListener('mouseout', function (e) {
      panel07On.classList.remove('-active');
    });
  }
}


function galleryFunc(){
  const parent = $('.o-content-gallery');
  if (!parent.length) return;

  const box = $('.c-box-gallery');
  const caption = $('.o-content-gallery__caption');
  box.on({
    'mouseenter': function() {
      const text = $(this).find('.c-desc').text();
      const type = $(this).attr('data-type');

      caption.text(text);
      parent.siblings('.layerParallax').find('.panelBg[data-type="'+type+'"]').addClass('-current');
    },
    'mouseleave': function() {
      caption.text('　');
      parent.siblings('.layerParallax').find('.panelBg').removeClass('-current');
    }
  });
}


function goodsTab(){
  const target = $('.o-content-goods');
  if(!target.length) return;

  const tabs = target.find('.o-content-goods__tab');
  const items = target.find('.o-content-goods__item');
  tabs.on('click', function(e){
    e.preventDefault();
    windowWidth = window.innerWidth;
    const tab = $(this);
    const item = tab.parents('.o-content-goods__item');
    const textarea = tab.siblings('.o-content-goods__textarea');
    // if (windowWidth > windowSm) {
    if (isPC) {
      items.removeClass('-current');
      item.addClass('-current');
    } else {
      if (tab.hasClass('-active')){
        tab.removeClass('-active');
        textarea.stop().slideUp();
      }else{
        tab.addClass('-active');
        textarea.stop().slideDown();
      }
    }
  });
}


function menuFunc(){
  const btn = $('.c-btn-menu');
  const menu = $('.c-menu');
  let scroll = $(window).scrollTop();
  
  btn.on('click', function(e){
    e.preventDefault();
    let windowWidth = window.innerWidth;
    if (btn.hasClass('-active')){
      menu.stop().fadeOut(1000);
      $('body').removeClass('-hold');
      btn.removeClass('-active');
      // if (windowWidth <= windowSm) {
      if (!isPC) {
        $('body').css({
          'position': '',
          'top': '',
          'left': '',
          'right': '',
        });
        $(window).scrollTop(scroll);
      }
    }else{
      menu.stop().fadeIn(1000);
      $('body').addClass('-hold');
      btn.addClass('-active');
      // if (windowWidth <= windowSm) {
      if (!isPC) {
        scroll = $(window).scrollTop();
        $('body').css({
          'position': 'fixed',
          'top': -1*scroll+'px',
          'left': 0,
          'right': 0,
        });
      }
    }
  });
}


function gallery(){
  let themeKey;
  let themeText;
  $('.c-box-gallery').on('click',function(e){
    e.preventDefault();
    themeKey = $(this).attr('data-type');
    themeText = $(this).find('.c-box-gallery__ttl').html();
    _getData(themeKey);
  });

  function _getData(theme){
    const galleryURL = 'https://www.tomiiyoshio.com/setting/gallery.php?theme='+theme;
    // const galleryURL = 'http://mercury.monomode.co/shashinkoubou/setting/gallery.php?theme='+theme;
    $.ajax({
      type: 'GET',
      url: galleryURL,
      dataType: "json",
      error: function() {
        console.log('error');
      },
      success: function(json) {
        _buildModal(json);
      }
    });
  }

  function _buildModal(json){
    let html = '';
    html += '\
    <div class="c-modal-gallery -loading">\
    <div class="c-modal-gallery__content">\
    <p class="c-modal-gallery__theme c-copy-m">'+themeText+'</p>\
    <div class="c-modal-gallery__main">\
    ';
      for (let i = 0; i < json.length; i++) {
        const data = json[i];
        const image = data.image;
        const title = data.title;
        const location = data.location;
        const country = data.country;
        let place = '';
        if (country == '') {
          place = '[ '+location+' ]';
        }else{
          place = '[ '+location+' <span>／</span> '+country+' ]';
        }
        html += '\
          <figure>\
            <a>\
              <img src="./assets/images/gallery/'+themeKey+'/'+image+'" alt="">\
            </a>\
            <figcaption class="c-copy-s">'+title+'</figcaption>\
            <p class="c-modal-gallery__location">'+place+'</p>\
          </figure>\
        ';
      }
    html += '\
        </div>\
        <div class="c-modal-gallery__controller">\
          <div class="c-modal-gallery__number">\
            <span class="-child">1</span>\
            <span>/</span>\
            <span class="-parent">'+json.length+'</span>\
          </div>\
        </div>\
        <div class="c-modal-gallery__nav">\
          <ul class="c-modal-gallery__nav__images">\
          ';
          for (let i = 0; i < json.length; i++) {
            const data = json[i];
            const image = data.image;
            html += '\
            <li>\
              <a>\
                <img src="./assets/images/gallery/'+themeKey+'/'+image+'" alt="">\
              </a>\
            </li>\
            ';
          }
          html += '\
          </ul>\
        </div>\
        <a class="c-modal-gallery__back" href="#">Back</a>\
      </div>\
    </div>\
    ';

    $('.c-modal-gallery').remove();
    $('.l-content').append(html);
    $('.c-modal-gallery__main').not('.slick-initialized').slick({
      fade: true,
      // autoplay: true,
      // autoplaySpeed: 5000,
      accessibility: false,
      speed: 600,
      dots: true,
      appendDots: '.c-modal-gallery__nav',
      prevArrow: '<div class="c-modal-gallery__arrow -prev"></div>',
      nextArrow: '<div class="c-modal-gallery__arrow -next"></div>',
      appendArrows: '.c-modal-gallery__controller',
    });
    const navImages = $('.c-modal-gallery__nav__images li');
    for (let i = 0; i < navImages.length; i++) {
      const navImage = navImages.eq(i);
      $('.c-modal-gallery__nav .slick-dots li').eq(i).html(navImage.html());
    }
    $('.c-modal-gallery__main').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      $('.c-modal-gallery__number .-child').text(nextSlide+1);
    });


    $('body').addClass('-hold -modal');

    const images = $('.c-modal-gallery__nav').find('img');
    let imagesCount = 0;
    $('.c-cursor').addClass('-loading');
    images.on('load', function(){
      imagesCount++;
      if (images.length == imagesCount) {
        $('.c-modal-gallery').removeClass('-loading');
        $('.c-cursor').removeClass('-loading');
      }
    });


    let scroll = $(window).scrollTop();

    _open();
    function _open(){
      // if (windowWidth <= windowSm) {
      if (!isPC) {
        scroll = $(window).scrollTop();
        $('body').css({
          'position': 'fixed',
          'top': -1*scroll+'px',
          'left': 0,
          'right': 0,
        });
      }
      
      _cursor();
    }

    $('.c-modal-gallery__main a').on('click', function(e){
      e.preventDefault();
      windowWidth = window.innerWidth;
      // if (windowWidth <= windowSm) return;
      if (!isPC) return;

      const thisImg = $(this).find('img').attr('src');
      $('.c-modal-gallery__zoom').remove();
      let zoomHtml = '\
        <div class="c-modal-gallery__zoom">\
          <div class="c-modal-gallery__zoom__img" style="background-image:url('+thisImg+');"></div>\
        </div>\
      ';
      $('.c-modal-gallery').append(zoomHtml);
      setTimeout(function(){
        $('.c-modal-gallery').addClass('-zoom');
        $('.c-cursor').addClass('-zoom');
      },10);
    });

    $('.c-modal-gallery').on('click', function(e){
      if(!$(this).hasClass('-zoom')) return;

      $('.c-modal-gallery').removeClass('-zoom');
      $('.c-cursor').removeClass('-zoom');
      console.log('close');
    });

    $('.c-modal-gallery__back').on('click', function(e){
      e.preventDefault();
      $('.c-modal-gallery').remove();
      $('body').removeClass('-hold -modal');
      // if (windowWidth <= windowSm) {
      if (!isPC) {
        $('body').css({
          'position': '',
          'top': '',
          'left': '',
          'right': '',
        });
        $(window).scrollTop(scroll);
      }
      $('.c-modal-gallery__main.slick-initialized').slick('unslick');
      $('.c-modal-gallery__nav.slick-initialized').slick('unslick');
    });


    function _cursor(){
      const target = document.getElementById('cursor');
      const links = document.querySelectorAll('.c-modal-gallery a, .c-modal-gallery__arrow');
      for (let i = 0; i < links.length; i++) {
        links[i].addEventListener('mouseover', function (e) {
          target.classList.add('-hover');
        });
        links[i].addEventListener('mouseout', function (e) {
          target.classList.remove('-hover');      
        });
      }
    }
  }
}



function modalServices(){
  const modal = $('#modalServices');
  const btn = $('#btnServices');
  const btnClose = $('.c-modal-services__close');
  let scroll = $(window).scrollTop();
  btn.on('click',function(e){
    e.preventDefault();
    modal.fadeIn(1000);
    $('body').addClass('-hold -modal');
    // if (windowWidth <= windowSm) {
    if (!isPC) {
      scroll = $(window).scrollTop();
      $('body').css({
        'position': 'fixed',
        'top': -1*scroll+'px',
        'left': 0,
        'right': 0,
      });
    }
  });
  btnClose.on('click',function(e){
    e.preventDefault();
    modal.fadeOut(1000);
    $('body').removeClass('-hold -modal');
    // if (windowWidth <= windowSm) {
    if (!isPC) {
      $('body').css({
        'position': '',
        'top': '',
        'left': '',
        'right': '',
      });
      $(window).scrollTop(scroll);
    }
  });
}



function modalMovie(){
  const modal = $('#modalMovie');
  const btn = $('#btnMovie');
  const btnClose = $('.c-modal-movie__close, .c-modal-movie__bg');
  let scroll = $(window).scrollTop();
  btn.on('click',function(e){
    e.preventDefault();
    modal.fadeIn(1000);
    const mov = btn.attr('data-movie');
    $('.c-modal-movie__movie video').attr('src',mov);
    // $('body').addClass('-hold -modal');
    // if (windowWidth <= windowSm) {
    //   scroll = $(window).scrollTop();
    //   $('body').css({
    //     'position': 'fixed',
    //     'top': -1*scroll+'px',
    //     'left': 0,
    //     'right': 0,
    //   });
    // }
  });
  btnClose.on('click',function(e){
    e.preventDefault();
    modal.fadeOut(1000);
    $('.c-modal-movie__movie video').attr('src','');
    // $('body').removeClass('-hold -modal');
    // if (windowWidth <= windowSm) {
    //   $('body').css({
    //     'position': '',
    //     'top': '',
    //     'left': '',
    //     'right': '',
    //   });
    //   $(window).scrollTop(scroll);
    // }
  });
}



function accordionSP(){
  const target = $('.c-accordion-sp');
  target.find('.c-ttl-border').on('click', function(){
    // if (windowWidth > windowSm) return;
    if (isPC) return;
    const btn = $(this);
    if(btn.hasClass('-active')){
      btn.removeClass('-active');
      btn.siblings('.c-accordion-sp__content').stop().slideUp();
    }else{
      btn.addClass('-active');
      btn.siblings('.c-accordion-sp__content').stop().slideDown();
    }
  })
}