iconSvg();

function iconSvg(){

  const items = document.getElementsByClassName('iconSvg');
  const iconArray = {
    'cursor': '<svg xmlns="http://www.w3.org/2000/svg" overflow="visible" viewBox="0 0 48.6 48.1"><defs/><style>.st0{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}</style><path d="M19.8.5C10.7 2.2 3.4 9.1 1.1 18.1M47.6 18.1C45.3 9.1 37.9 2.2 28.8.5M.5 27.1c1.3 10.3 9.2 18.6 19.3 20.6M28.8 47.6c10.1-1.9 18-10.2 19.3-20.6" class="st0"/></svg>',
    'facebook': '<svg xmlns="http://www.w3.org/2000/svg" overflow="visible" viewBox="0 0 1024 1017.8"><defs/><path fill="#fff" d="M1024 512C1024 229.2 794.8 0 512 0S0 229.2 0 512c0 255.6 187.2 467.4 432 505.8V660H302V512h130V399.2C432 270.9 508.4 200 625.4 200c56 0 114.6 10 114.6 10v126h-64.6c-63.6 0-83.4 39.5-83.4 80v96h142l-22.7 148H592v357.8c244.8-38.4 432-250.2 432-505.8z"/></svg>',
    'blank': '<svg xmlns="http://www.w3.org/2000/svg" overflow="visible" viewBox="0 0 39.9 34.2"><defs/><path fill="#fff" d="M38.9 0H11.8c-.6 0-1 .4-1 1v9.8H1c-.6 0-1 .4-1 1v21.5c0 .6.4 1 1 1h27.2c.6 0 1-.4 1-1v-9.8H39c.6 0 1-.4 1-1V1c-.1-.6-.5-1-1.1-1zM27.2 32.2H2V12.8h8.8v9.8c0 .6.4 1 1 1h15.4v8.6zm10.7-10.7H12.8V2H38v19.5z"/></svg>',
  };

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const name = item.getAttribute('data-icon');
    if (name) {
      const icon = iconArray[name];
      item.innerHTML = icon;
    }
  }
}
