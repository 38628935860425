// contact page js

contactFunction();

function contactFunction(){
  if (!document.body.classList.contains('page-contact')) return;

  signDrawLower();
  modalPrivacy();
  mousePrallaxLower();
}

function signDrawLower(){
  const anime1 = function(){
    new Vivus(
      'sign1',
      {
        type: 'scenario-sync',
        duration: 16,
        forceRender: false ,
        animTimingFunction:Vivus.EASE
      }
    );
  }
  anime1();
}





function modalPrivacy(){
  const modal = $('#modalPrivacy');
  const btn = $('#btnPrivacy');
  const btnClose = $('.c-modal-privacy__close');
  let scroll = $(window).scrollTop();
  btn.on('click',function(e){
    e.preventDefault();
    modal.fadeIn(1000);
    $('body').addClass('-hold -modal');
    if (windowWidth <= windowSm) {
      scroll = $(window).scrollTop();
      $('body').css({
        'position': 'fixed',
        'top': -1*scroll+'px',
        'left': 0,
        'right': 0,
      });
    }
  });
  btnClose.on('click',function(e){
    e.preventDefault();
    modal.fadeOut(1000);
    $('body').removeClass('-hold -modal');
    if (windowWidth <= windowSm) {
      $('body').css({
        'position': '',
        'top': '',
        'left': '',
        'right': '',
      });
      $(window).scrollTop(scroll);
    }
  });
}


function mousePrallaxLower(){
  // if (windowWidth > windowSm) {
  if (isPC) {
    const layers = document.getElementsByClassName('layerParallax');
    for (let i = 0; i < layers.length; i++) {
      const layer = layers[i];
      const parallax = new Parallax(layer, {
        clipRelativeInput: true, //要素の端にカーソルが達したら動きを止める
        hoverOnly: true, //カーソルが要素の上にある場合のみ有効
        invertX: true, //X軸の動きを反転。falseにするとマウスと同じ方向に動く
        invertY: true, //Y軸の動きを反転。falseにするとマウスと同じ方向に動く
        limitX: false, //X軸の動きを制限する
        limitY: false, //Y軸の動きを制限する
        scalarX: 10.0, //X軸の移動速度と範囲を増減
        scalarY: 10.0, //Y軸の移動速度と範囲を増減
        // frictionX: .1, //X軸のレイヤーの速度。0.00〜1.00の範囲内で指定可能
        // frictionY: .1, //Y軸のレイヤーの速度。0.00〜1.00の範囲内で指定可能
        originX: .5, //X軸のマウスの初期位置。デフォルトの0.5は画面または要素の中心
        originY: .5, //Y軸マウスの初期位置。デフォルトの0.5は画面または要素の中心
      });
    }
  }
  $(window).on('scroll', function(){
    const scroll = $(window).scrollTop();
    if (windowWidth > windowSm) return;
    $('.o-content-contact').each(function(){
      const bg = $(this).children('.layerParallax');
      
      if(!bg.length) return;

      const dH = $(window).height();
      const start = $(this).offset().top;
      const end = start + $(this).outerHeight();

      if(scroll <= start - dH || end <= scroll) return;
      
      const top = scroll - start;
      const rate = (top / dH) / 3 * 100 * -1;
      bg.css({
        'top': top+'px',
        'transform': 'translateY('+rate+'%)',
      });
    });
  });
}